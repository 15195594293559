import React, { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

import Spinner from "../../components/spinner";
import Error from "../../components/error/error";

import shurjopay from "../../assets/shurjopay.webp";

export class Checkout extends Component {
  constructor() {
    super();

    let cart = JSON.parse(localStorage.getItem("cart"));
    let packageExpire =
      jwtDecode(localStorage.getItem("token")).packageExpire || Date.now();

    console.log(packageExpire);

    this.state = {
      loading: false,
      error:
        Object.fromEntries(new URLSearchParams(window.location.search)).error ||
        null,
      cart: cart || [],
      packageExpire: packageExpire === Date.now() ? null : packageExpire,
      newExpire:
        cart.type === "Daily"
          ? packageExpire + 24 * 60 * 60 * 1000
          : cart.type === "Weekly"
          ? packageExpire + 7 * 24 * 60 * 60 * 1000
          : cart.type === "Monthly"
          ? packageExpire + 30 * 24 * 60 * 60 * 1000
          : null,
    };

    console.log(this.state.newExpire);
  }

  async componentDidMount() {
    if (this.state.cart.length <= 0) window.location.href = "/";
    if (this.state.error === "payment_failed")
      return this.setState({
        error:
          "Your order has been cancelled due to payment failure. Please try again!",
      });
  }

  async order(event) {
    event.preventDefault();

    this.setState({ error: null, loading: true });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/package/order`,
        {
          type: this.state.cart.type,
          price: this.state.cart.price,
        },
        config
      );

      if (data.success) window.location.href = data.url;
    } catch (error) {
      this.setState({
        error: "Something went wrong! Please try again.",
        loading: false,
      });
    }
  }

  render() {
    return (
      <div className="checkout">
        <Navbar></Navbar>
        <div className="container mt-5 text-center">
          <h1>Checkout</h1>
          {this.state.packageExpire ? (
            <h4>
              Your current package is available till:{" "}
              <span className="text-red">
                {new Date(this.state.packageExpire).toDateString()}
              </span>
            </h4>
          ) : null}
          {this.state.error ? <Error message={this.state.error}></Error> : null}
        </div>
        {this.state.loading ? (
          <Spinner></Spinner>
        ) : (
          <div className="container pb-5 pt-3">
            <div
              style={{
                boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
                borderRadius: "4px",
                border: "1px solid #e0e0e0",
              }}
              className="px-4 py-4"
            >
              <h2>Your cart</h2>
              <hr />
              <div className="d-flex justify-content-between pb-3">
                <h6 className="text">{this.state.cart.type} &#215; 1</h6>
                <h6>{this.state.cart.price} BDT</h6>
              </div>

              {this.state.packageExpire ? (
                <h6 className="pb-2">
                  Extend to: {new Date(this.state.newExpire).toDateString()}
                </h6>
              ) : (
                <h6 className="pb-2">
                  Expires in: {new Date(this.state.newExpire).toDateString()}
                </h6>
              )}

              <div className="d-flex justify-content-between">
                <h3>Total</h3>
                <h3>{this.state.cart.price} BDT</h3>
              </div>
            </div>

            <div className="payment my-3 py-2">
              <div
                className="d-flex justify-content-between align-items-center px-3 py-2"
                style={{ background: "#f3f3f3" }}
              >
                <p style={{ fontWeight: 600 }} className="my-2">
                  Shurjopay
                </p>
                <img
                  src={shurjopay}
                  alt="shurjopay"
                  style={{
                    maxWidth: "200px",
                    margin: "0 15px",
                    maxHeight: "50px",
                  }}
                />
              </div>
              <div className="bg-light px-3 pb-2 pt-3">
                <p>Pay securely using ShurjoPay</p>
              </div>
            </div>

            <div className="d-grid">
              <button className="button" onClick={(event) => this.order(event)}>
                Place order
              </button>
            </div>
          </div>
        )}
        <Footer></Footer>
      </div>
    );
  }
}

export default Checkout;
