import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTag,
  faLocationArrow,
  faClock,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

import Spinner from "../../components/spinner";

import "./find.scss";

export class FindLawyers extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      lawyer: "",
    };
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/`,
        config
      );

      console.log(data.lawyer);

      if (data.success) this.setState({ loading: false, lawyer: data.lawyer });
      console.log(this.state.lawyer);
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  render() {
    return (
      <div className="lawyers">
        <Navbar></Navbar>
        <div className="container my-5">
          <h1>Lawyers</h1>
          <p>Browse Lawyers</p>

          <div className="lawyers-list">
            <div className="row mt-5">
              <div className="col-md-3">
                <h3 className="mb-4">Filters</h3>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Search Lawyers"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Location"
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Categories"
                />
                <div className="d-grid">
                  <button className="button">Search</button>
                </div>
              </div>
              <div className="col-md-9">
                {this.state.loading ? (
                  <Spinner></Spinner>
                ) : (
                  this.state.lawyer.map((profile, index) => (
                    // <div
                    //   className="lawyer-profile mt-3"
                    //   onClick={() =>
                    //     (window.location.href = `/lawyer/${profile._id}`)
                    //   }
                    // >
                    //   <div className="row">
                    //     <div className="col-md-3">
                    //       <div className="image">
                    //         <img
                    //           src={profile.picture}
                    //           alt={profile.name}
                    //           className="img-fluid"
                    //         />
                    //       </div>
                    //     </div>
                    //     <div className="col-md-9 d-flex align-items-center">
                    //       <div className="d-">
                    //         <h3 className="d-flex align-items-start">
                    //           {profile.name}
                    //           <span className="verified ms-2">
                    //             {profile.verified ? (
                    //               <FontAwesomeIcon
                    //                 icon={faCircleCheck}
                    //                 size="2xs"
                    //               ></FontAwesomeIcon>
                    //             ) : null}
                    //           </span>
                    //         </h3>
                    //         <h5>
                    //           <div className="red">{profile.category}</div>
                    //         </h5>
                    //         <p>{profile.location.region}</p>
                    //         <div className="d-flex align-items-center">
                    //           {profile.pricing ? (
                    //             <div className="d-flex align-items-center lawyer-info">
                    //               <div className="pricing">
                    //                 <FontAwesomeIcon
                    //                   icon={faTag}
                    //                   size="1x"
                    //                 ></FontAwesomeIcon>
                    //               </div>
                    //               <span className="ms-2">
                    //                 &#2547; {profile.pricing[0]} - &#2547;{" "}
                    //                 {profile.pricing[1]}
                    //               </span>
                    //             </div>
                    //           ) : null}
                    //           {profile.booking ? (
                    //             <div className="d-flex align-items-center lawyer-info">
                    //               <div className="booking">
                    //                 <FontAwesomeIcon
                    //                   icon={faBoltLightning}
                    //                   size="1x"
                    //                 ></FontAwesomeIcon>
                    //               </div>
                    //               <span className="ms-2">Instant Booking</span>
                    //             </div>
                    //           ) : null}
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    <div className="lawyer-profile" key={index}>
                      <div className="row">
                        <div className="col-md-8 col-sm-12">
                          <div className="d-flex flex-row gap-3">
                            <img
                              className="img-fluid"
                              src={profile.picture}
                              alt={profile.name}
                            ></img>
                            <div className="lawyer-info">
                              <h4 className="d-flex align-items-start">
                                {profile.name}
                                <span className="verified ms-2">
                                  {profile.verified ? (
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      size="xs"
                                    ></FontAwesomeIcon>
                                  ) : null}
                                </span>
                              </h4>
                              {profile.category.map((cat, index) => (
                                <h7 className="category" key={index}>
                                  {cat.label}
                                </h7>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 lawyer-details">
                          <div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                size="lg"
                                className="color-"
                              />
                              <div className="details">
                                {profile.location.region}
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faClock} size="lg" />
                              <div className="details">
                                <span
                                  className={
                                    new Date().getDay() === 1 ? "bold" : ""
                                  }
                                >
                                  Mon
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 2 ? "bold" : ""
                                  }
                                >
                                  Tue
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 3 ? "bold" : ""
                                  }
                                >
                                  Wed
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 4 ? "bold" : ""
                                  }
                                >
                                  Thu
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 5 ? "bold" : ""
                                  }
                                >
                                  Fri
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 6 ? "bold" : ""
                                  }
                                >
                                  Sat
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 0 ? "bold" : ""
                                  }
                                >
                                  Sun
                                </span>
                                ,
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faTag} size="lg" />
                              <div className="details">
                                Starting from{" "}
                                <span className="bold">
                                  &#2547; {profile.pricing[0]}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faCalendar} size="lg" />
                              <div className="details available">
                                Available Today
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              className="button"
                              onClick={() =>
                                (window.location.href = `/lawyer/${profile._id}`)
                              }
                            >
                              View Full Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default FindLawyers;
